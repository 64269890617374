import request from '../utils/request'
 /*
 * ============新增账户
 * @params{
 *
 * addAccountVo:{
 *      bankaddress: string      开户行
 *      bankname:string          银行名称
 *      name:string              姓名
 *      no:string                账号
 *      type:init                充值类型
 *      status:init              状态
 *     }
 * }
 * */
 //查询
export function positionData(data) {
    return request({
        url:'/platform/cms/advertise/position/data',
        method:'post',
        data
    })
}
//添加
export function positionAdd(data) {
    return request({
        url:'/platform/cms/advertise/position/addDo',
        method:'post',
        data
    })
}
//修改
export function positionEdit(data) {
    return request({
        url:'/platform/cms/advertise/position/editDo',
        method:'post',
        data
    })
}
//删除一个
export function positionDeleteOne(data) {
    return request({
        url:'/platform/cms/advertise/position/delete/'+data,
        method:'post',
    })
}
//删除多个
export function positionDeleteMore(data) {
    return request({
        url:'/platform/cms/advertise/position/delete',
        method:'post',
        data
    })
}
//广告管理列表
export function advertiseData(data) {
    return request({
        url:'/platform/cms/advertise/data',
        method:'post',
        data
    })
}
//添加
export function advertiseAdd(data) {
    return request({
        url:'/platform/cms/advertise/addDo',
        method:'post',
        data
    })
}
//修改
export function advertiseEdit(data) {
    return request({
        url:'/platform/cms/advertise/editDo',
        method:'post',
        data
    })
}
//删除一个
export function advertiseDeleteOne(data) {
    return request({
        url:'/platform/cms/advertise/delete/'+data,
        method:'post',
    })
}
//删除多个
export function advertiseDeleteMore(data) {
    return request({
        url:'/platform/cms/advertise/delete',
        method:'post',
        data
    })
}
//餐点信息
export function siteData(data) {
    return request({
        url:'/platform/food/site/data',
        method:'post',
        data
    })
}
/*
*==============账户列表
* @params{
* searchAccountVo:{    查询条件
*   name:string    姓名
*   no:string      账户
*   status:init    状态
*   type:init      类型
* }
* searchByPageVo:{
*    page:init
*    rows:init
* }
* */

/*
* ============更新账户
* @params{
* updateAccountVo:{
*   bankaddress: string      开户行
*   bankname:string          银行名称
*   name:string              姓名
*   no:string                账号
*   type:init                充值类型
*   status:init              状态
*   }
* }
* */

