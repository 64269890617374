import request from '../utils/request'


/*
*==============客服列表
* @params{
* searchAccountVo:{       查询条件
*   accountname:string    客服名称
* }
* searchByPageVo:{
*    page:init
*    rows:init
* }
* */
//餐点列表
export function foodSiteData(data) {
    return request({
        url: '/platform/food/site/data',
        method: 'get',
        data
    })
}
//餐点添加
export function foodSiteAdd(data) {
    return request({
        url: '/platform/food/site/addDo',
        method: 'post',
        data
    })
}
//餐点修改
export function foodSiteEdit(data) {
    return request({
        url: '/platform/food/site/editDo',
        method: 'post',
        data
    })
}
//打印机列表
export function printsData(data) {
    return request({
        url: '/platform/food/prints/data',
        method: 'post',
        data
    })
}
//修改打印机
export function printsEdit(data) {
    return request({
        url: '/platform/food/prints/editDo',
        method: 'post',
        data
    })
}
//打印记录
export function taskData(data) {
    return request({
        url: '/platform/food/print/task/data',
        method: 'post',
        data
    })
}
/*
* ============更新客服信息
* @params{
* updateAccountVo:{
* id:
*   accountname: string      账号
*   auditlimit:string          银行名称
*   headimg:string              头像
*   nickname:string                昵称
*   whileipaddress:init                白名单
*   status:init              状态
*   }
* }
* */
