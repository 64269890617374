<template>
    <el-container class="page-container">
<!--                打印记录-->
        <!--<el-header class="page-header" style="min-height: 60px;height: auto">-->

        <!--</el-header>-->
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form
                    label-position="left"
                    label-width="80px"
                    :model="query"
                    ref="searchForm"
            >
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-input
                                placeholder="请输入内容"
                                v-model="query.searchKeyword"
                                @keyup.enter.native="doSearch"
                        >
                            <el-select
                                    v-model="query.searchName"
                                    slot="prepend"
                                    placeholder="查询类型"
                                    style="width: 120px;"
                            >
                                <el-option label="任务名" value="name"></el-option>
                            </el-select>
                        </el-input>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column prop="name" label="任务名" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="content" label="任务内容" :show-overflow-tooltip="true">
                </el-table-column>

                <el-table-column
                        prop="state"
                        label="状态"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.state==-1">失败</span>
                        <span v-if="scope.row.state==0">待发送</span>
                        <span v-if="scope.row.state==1">已发送</span>
                        <span v-if="scope.row.state==2">成功</span>
                        <span v-if="scope.row.state==3">队列中</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="orderId"
                        label="订单号"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="opAt"
                        label="打印时间"
                        :show-overflow-tooltip="true"
                        :formatter="opAtformate"
                >

                </el-table-column>
                <el-table-column
                        prop="msg"
                        label="消息"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.locate==0">后厨</span>
                        <span v-if="scope.row.locate==1">收银</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="printNum"
                        label="打印次数"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
            </el-table>
            <br />
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount"
            >
            </el-pagination>
        </el-main>
        <!--//编辑管理员-->
    </el-container>
</template>

<script>
  import { mapGetters } from "vuex";
  import formDialog from "../../components/formDialog";
  import { taskData, foodSiteAdd ,foodSiteEdit,printsEdit} from "../../api/storeArea";
  import moment from 'moment'
  import {siteData} from '../../api/ads'
  export default {
    name: "companyManagement",
    components: { formDialog },
    data() {
      return {
        tableData: [],
        pageInfo: {
          totalCount: 0,
          pageNumber: 1,
          pageSize: 10,
          pageOrderName: "opAt",
          pageOrderBy: "descending"
        },
        query: {
          searchName: "",
          searchKeyword: ""
        },
        formData: {},
      };
    },
    computed: {
      ...mapGetters(["userInfo"])
    },
    methods: {
      opAtformate(row){
        return moment(row.opAt*1000).format("YYYY-MM-DD HH:mm:ss");
      },
      handleSearch() {
        this.pageInfo.pageNumber = 1;
        this.taskData();
      },
      async taskData() {
        try {
          let query = Object.assign({}, this.pageInfo, this.query);
          let formData = {};
          for (let i in query) {
            if (query[i] !== "" && i !== "total" && query[i] !== -1) {
              formData[i] = query[i];
            }
          }
          let resonse = await taskData(formData);
          let { data } = resonse;
          this.tableData = data.list;
          this.pageInfo.totalCount = data.totalCount;
        } catch (e) {
          console.log(e);
        }
      },
      clearSearch() {
        (this.query = {
          searchName: "",
          searchKeyword: ""
        }),
          this.handleSearch();
      },

      handleSizeChange(val) {
        this.pageInfo.pageSize = val;
        this.pageInfo.page = 1;
        this.taskData();
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.pageInfo.pageNumber = val;
        this.taskData();
      },
    },
    mounted() {
      this.taskData();
    }
  };
</script>

<style lang="less">
    .btn-group {
        button {
            margin-left: 20px;
        }
    }
    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }
    .el-input__prefix {
        left: 15px;
        color: #303133;
        i {
            font-style: normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348eed;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #dcdfe6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409eff;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }
</style>
